<template>
  <v-container fluid>
    <v-row v-if="!nuevo_reclamo">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="items">
          <template v-slot:top>
            <v-toolbar flat dense>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="nuevo_reclamo = !nuevo_reclamo"
                >Nuevo</v-btn
              >
            </v-toolbar>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="nuevo_reclamo">
      <v-col cols="3">
        <v-autocomplete
          v-model="tipo_reclamo"
          label="Tipo"
          :items="items_tipo"
          item-text="nombre"
          item-value="tipo"
          outlined
          dense
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto">
        <v-icon size="30" color="black" @click="nuevo_reclamo = !nuevo_reclamo"
          >far fa-times-circle</v-icon
        >
      </v-col>
    </v-row>
    <!-- AUTOS -->
    <v-row v-if="nuevo_reclamo && tipo_reclamo == 'auto'">
      <v-col cols="12" md="4">
        <v-text-field
          label="Reclamo"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Póliza" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Placa o Nombre"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Fecha de reclamo"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Fecha de pago"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Cliente"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Deducible"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Nombre de cliente"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Aseguradora"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Taller" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Características de Auto"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Ajustador"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Analista"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Estado" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Valor indemnizado"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field label="Motivo" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary">Agregar gestión</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          label="Fecha de gestion"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field
          label="Gestion"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- INCENDIO Y DIVERSOS -->
    <v-row v-if="nuevo_reclamo && tipo_reclamo == 'diversos'">
      <v-col cols="12" md="4">
        <v-text-field
          label="Reclamo"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Póliza" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Tipo de seguro"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Ubicación"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="# de riesgo"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Cliente"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Deducible"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Participación"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Nombre de cliente"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Aseguradora"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Ajustador"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Analista"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Estado" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Valor indemnizado"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field label="Motivo" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary">Agregar gestión</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          label="Fecha de gestion"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field
          label="Gestion"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- TRANSPORTE DE MERCADERIA INTERNO -->
    <v-row v-if="nuevo_reclamo && tipo_reclamo == 'transporte'">
      <v-col cols="12" md="4">
        <v-text-field
          label="Reclamo"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Póliza" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Tipo de seguro"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="# de certificado"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="# de factura"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Cliente"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Deducible"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Participación"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Nombre de cliente"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Aseguradora"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Ajustador"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Analista"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Estado" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Valor indemnizado"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field label="Motivo" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary">Agregar gestión</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          label="Fecha de gestion"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field
          label="Gestion"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- PERSONAS -->
    <v-row v-if="nuevo_reclamo && tipo_reclamo == 'personas'">
      <v-col cols="12" md="4">
        <v-text-field
          label="Reclamo"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field label="Póliza" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Tipo de seguro"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Hospital o clinica"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Médico"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Cliente"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Deducible"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
          label="Participación"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Nombre de cliente"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Aseguradora"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field
          label="Analista"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field label="Estado" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Valor indemnizado"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-text-field label="Motivo" outlined dense hide-details></v-text-field>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary">Agregar gestión</v-btn>
      </v-col>
      <v-col cols="12" md="2">
        <v-text-field
          label="Fecha de gestion"
          outlined
          dense
          hide-details
          type="date"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="10">
        <v-text-field
          label="Gestion"
          outlined
          dense
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    tipo_reclamo: "auto",
    items_tipo: [
      { tipo: "auto", nombre: "AUTO" },
      { tipo: "diversos", nombre: "INCENDIO/DIVERSOS" },
      { tipo: "transporte", nombre: "TRANSPORTE MERCADERIA" },
      { tipo: "personas", nombre: "PERSONAS" },
    ],
    nuevo_reclamo: false,
    headers: [
      { text: "N° Reclamo", value: "n_reclamo" },
      { text: "N° Póliza", value: "num_poliza" },
    ],
    items: [],
  }),
};
</script>