var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers_prox_vencer,"items":_vm.items_prox_vencer,"search":_vm.search,"item-key":"id_polizas","show-select":"","loading":_vm.loading,"loading-text":"Cargando... Porfavor espere"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"mr-2"},[_vm._v(" RENOVACIONES ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Buscar","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fa-solid fa-filter")])],1)]}}])},[_c('v-card',{attrs:{"width":"300"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Desde","outlined":"","dense":"","hide-details":"","type":"date","clearable":""},model:{value:(_vm.filtro_fecha_desde),callback:function ($$v) {_vm.filtro_fecha_desde=$$v},expression:"filtro_fecha_desde"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Hasta","outlined":"","dense":"","hide-details":"","type":"date","clearable":""},model:{value:(_vm.filtro_fecha_hasta),callback:function ($$v) {_vm.filtro_fecha_hasta=$$v},expression:"filtro_fecha_hasta"}})],1),_c('v-col',{attrs:{"cols":"12"},on:{"click":function($event){return _vm.listarPolizasProximasVencer('filtro')}}},[_c('v-btn',{attrs:{"color":"primary","block":""}},[_vm._v("Filtrar")])],1)],1)],1)],1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v("Descargar")])]}}])},[_c('v-list',[_vm._l((_vm.items_descarga),function(item,i){return [(item.is_show)?_c('v-list-item',{key:i,on:{"click":function($event){return _vm.descargarReporteProxVencer(item, 'ppv')}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.documento))])],1):_vm._e()]})],2)],1)],1)]},proxy:true},{key:"item.fecha_fin",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.formatoFechaSV(item.fecha_fin))+" ")])]}},{key:"item.action_view",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.openTabPoliza(item)}}},[_vm._v("fas fa-external-link-alt")])]}}],null,true),model:{value:(_vm.seleccion_polizas_prox_vencer),callback:function ($$v) {_vm.seleccion_polizas_prox_vencer=$$v},expression:"seleccion_polizas_prox_vencer"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }